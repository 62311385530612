import React, { useEffect, useState } from 'react';

import Image from 'next/image';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { fetchGetAdsCount } from '@/apis/game';
import { useAdsgram } from '@/hooks/useAds';
import { ShowPromiseResult } from '@/types/adsgram';
import { Button } from '@/components/ui/button';
import { ShareButton } from '../ShareButton';
import { cn } from '@/lib/utils';
import { track } from '@/utils/thinkdata/track';

type AdsButtonProps = {
  text: string;
  onAdsReward: () => void;
  onAdsError: (result: ShowPromiseResult) => void;
  className?: string;
  shadowColor?: string;
  onShareSuccess?: () => void;
  trackData?: {
    scene: string;
    entrance: string;
  };
};

export const AdsButton: React.FC<AdsButtonProps> = ({
  text,
  onAdsReward,
  onAdsError,
  className,
  shadowColor,
  onShareSuccess,
  trackData,
}) => {
  const [adsData, setAdsData] = useState({} as any);
  const [err, setErr] = useState(false);
  const handleError = (result: ShowPromiseResult) => {
    setErr(true);
    onAdsError(result);
  };

  const showAd = useAdsgram({
    onReward: onAdsReward,
    onError: handleError,
    trackData,
  });

  const getAdsCounts = async () => {
    const resp = await fetchGetAdsCount();
    setAdsData(resp);
  };

  useEffect(() => {
    getAdsCounts();
  }, []);

  const handleWatchAds = () => {
    track('ad_trigger', {
      adScene: trackData?.scene,
      entrance: trackData?.entrance,
    });
    showAd();
  };

  // 广告次数耗尽
  if (!adsData?.allow || err) {
    return (
      <ShareButton
        text={text}
        onShareSuccess={onShareSuccess}
        className={className}
        trackData={trackData}
      />
    );
  }

  return (
    <Button
      onClick={handleWatchAds}
      className={cn(
        'flex justify-center items-center gap-2 px-[20px] h-[60px] flex-shrink-0 bg-[#F6C334] text-lg text-white stroke-1 stroke-black',
        className,
      )}
      shadowColor={shadowColor || '#F6C334'}
    >
      <div className="flex flex-col justify-center items-center">
        <Image
          width={30}
          height={30}
          src={getPublicAsset('/images/game/ads.png')}
          alt=""
        />
        {/*<span className="text-xs">*/}
        {/*  ({adsData?.todayWatchAdTimes}/{adsData?.maxWatchAdTimes})*/}
        {/*</span>*/}
      </div>
      <span className="text-nowrap">{text}</span>
    </Button>
  );
};
