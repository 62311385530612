import { useCallback, useEffect, useRef } from 'react';
import { AdController, ShowPromiseResult } from '@/types/adsgram';
import { isProduction } from '@/utils/checkRuntime';
import { isAdPlayingAtom } from '@/store/runtime';
import { useAtom } from 'jotai';
import * as Sentry from '@sentry/nextjs';
import { track } from '@/utils/thinkdata/track';

export interface useAdsgramParams {
  blockId?: string;
  onReward: () => void;
  onError?: (result: ShowPromiseResult) => void;
  trackData?: {
    scene: string;
    entrance: string;
  };
}

export function useAdsgram({
  blockId = process.env.NEXT_PUBLIC_AD_BLOCKID,
  onReward,
  onError,
  trackData,
}: useAdsgramParams): () => Promise<void> {
  const AdControllerRef = useRef<AdController | undefined>(undefined);
  const [, setIsAdPlaying] = useAtom(isAdPlayingAtom);

  useEffect(() => {
    AdControllerRef.current = window?.Adsgram?.init({
      blockId,
      debug: !isProduction,
      debugBannerType: 'FullscreenMedia',
    });

    AdControllerRef.current?.addEventListener('onStart', () => {
      track('ad_show', {
        adScene: trackData?.scene,
        entrance: trackData?.entrance,
      });
      setIsAdPlaying(true);
    });

    AdControllerRef.current?.addEventListener('onReward', () => {
      setIsAdPlaying(false);
    });

    AdControllerRef.current?.addEventListener('onError', () => {
      setIsAdPlaying(false);
    });
  }, [blockId]);

  return useCallback(async () => {
    if (AdControllerRef.current) {
      AdControllerRef.current
        .show()
        .then(() => {
          // user skip ad
          // onSkip()
          // user watch ad till the end
          track('ad_close', {
            adScene: trackData?.scene,
            entrance: trackData?.entrance,
          });
          onReward();
        })
        .catch((result: ShowPromiseResult) => {
          track('ad_fail', {
            adScene: trackData?.scene,
            entrance: trackData?.entrance,
          });
          // user get error during playing ad or skip ad
          onError?.(result);
          Sentry.captureMessage('Adsgram error', {
            extra: {
              error: JSON.stringify(result),
            },
          });
        });
    } else {
      onError?.({
        error: true,
        done: false,
        state: 'load',
        description: 'Adsgram script not loaded',
      });
    }
  }, [onError, onReward]);
}
