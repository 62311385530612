import { Button } from '@/components/ui/button';
import { getItemCount } from '@/app/[lng]/game/utils';
import { get } from 'lodash-es';
import useGameItemsMap from '@/app/[lng]/game/hooks/useGameItemsMap';
import useImpactOccurred from '@/hooks/useVibration';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { userInfoAtom, userGameStatus } from '@/store/userInfo';
import { useAtom } from 'jotai';
import { Subtract } from '@/utils/decimal/operations';
import { useMutation } from '@tanstack/react-query';
import { EventId, gameStart, type UserMetaModel } from '@/apis/game';
import { useRouter } from 'next/navigation';
import { useTranslations, useLocale } from 'next-intl';
import { useToast } from '@/components/ui/use-toast';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import LocalizedLink from '@/components/ui/LocalizedLink';
import { UserGameStatusDTO } from '@/apis/login';
import { DifficultyDisplay } from '@/components/lobby/DifficultyDisplay';
import { getPublicAsset } from '@/utils/getPublicAsset';
import Image from 'next/image';
import { TicketDialog } from '@/app/[lng]/game/components/TicketDialog';
import formatMoney from '@/utils/decimal/formatMoney';
import { formatLargeNumber } from '@/utils/number/formatLargeNumber';
import { track } from '@/utils/thinkdata/track';

const usePlayGame = () => {
  const [isContinueOpen, setIsContinueOpen] = useState(false);
  const gameItemsMap = useGameItemsMap();
  const [impactOccurred] = useImpactOccurred('heavy');
  const [userGame, setUserGameStatus] = useAtom(userGameStatus);
  const [isGamePageLoading, setIsGamePageLoading] = useState(false);
  const { journey } = userGame;
  const locale = useLocale();
  const t = useTranslations();
  const canFreePlay: boolean = useMemo(() => {
    const freeTimes = get(userGame, 'playFreeTimes', '0') || '0';
    const playTimes = get(userGame, 'playTimes', '0') || '0';
    return Number(Subtract(freeTimes, playTimes)) > 0;
  }, [userGame]);
  const lastFreeTimes = useMemo(() => {
    const freeTimes = get(userGame, 'playFreeTimes', '0') || '0';
    const playTimes = get(userGame, 'playTimes', '0') || '0';
    return Math.max(Subtract(freeTimes, playTimes), 0);
  }, [userGame]);
  const { toast } = useToast();
  const ticket = getItemCount(get(gameItemsMap, 'ticket.haveCount', 0));
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const handleGameStart = () => {
    impactOccurred();
    if (!canFreePlay && ticket === '0') {
      setTicketDialogShow(true);
      return;
    }
    setIsContinueOpen(true);
  };
  const handleGameNextlevel = (callback?: (data: UserMetaModel) => void) => {
    goToGame(
      { eventId: EventId.start },
      {
        onSuccess: (data) => {
          callback?.(data);
        },
      },
    );
  };
  const hasSufficientBalance = useMemo(
    () => Number(journey?.nextPrice) < Number(userInfo?.gold),
    [journey, userInfo],
  );
  const canEnterNextLevel = useMemo(() => {
    return canFreePlay || (ticket !== '0' && hasSufficientBalance);
  }, [canFreePlay, ticket, hasSufficientBalance]);

  const handleContinueGameStart = () => {
    setTicketDialogShow(false);
    setIsContinueOpen(true);
  };
  const { mutate: goToGame, isPending: isFetchGameStartLoading } = useMutation({
    mutationFn: gameStart,
    onSuccess: (data) => {
      setIsGamePageLoading(true);
      track('challenge_start', {
        isFreePlay: canFreePlay,
        ticket: getItemCount(get(gameItemsMap, 'ticket.haveCount', 0)),
        task_id: journey.currentLevel,
        cost_coin: journey?.nextPrice,
      });
      setUserInfo({
        ...data.userModel,
      });
    },
    onError: ({ data }: any) => {
      toast({
        description: data.status.message,
        duration: 3000,
      });
    },
  });
  const [ticketDialogShow, setTicketDialogShow] = useState(false);
  const isGameLoading = useMemo(() => {
    return isFetchGameStartLoading || isGamePageLoading;
  }, [isFetchGameStartLoading, isGamePageLoading]);

  const linkRef = useRef<HTMLAnchorElement>(null);
  const goToGamePage = () => {
    linkRef.current?.click();
  };

  const PlayGameButton = (
    <div>
      <Button
        onClick={handleGameStart}
        className={`${isGameLoading ? 'pointer-events-none ' : ''}block bg-[#E2F43E]  pt-[0.3rem] pb-[0.5rem] px-[30px] select-none   min-h-[3.5rem]`}
        shadowColor="#858F23"
      >
        <div className="flex-row justify-center items-center">
          <span
            className={
              'font-bold stroke-chocolate text-white font-mono text-xl stroke-black text-nowrap'
            }
          >
            {isGameLoading ? 'Starting...' : t('play-game')}
          </span>
          <div className="text-[#6e5c20] text-xs">
            <span className={'font-bold'}>
              {lastFreeTimes}/{userGame.playFreeTimes} {t('free-attempts')}
            </span>
          </div>
        </div>
      </Button>

      <ContinueDialog
        isOpen={isContinueOpen}
        onClose={() => setIsContinueOpen(false)}
        onContinue={() => {
          !isGameLoading &&
            goToGame(
              { eventId: EventId.start },
              {
                onSuccess: () => {
                  goToGamePage();
                },
              },
            );
        }}
        journey={journey}
        userGame={userGame}
        userInfo={userInfo}
        hasSufficientBalance={hasSufficientBalance}
        isPending={isGameLoading}
        t={t}
        tickets={getItemCount(get(gameItemsMap, 'ticket.haveCount', 0))}
      />
      <TicketDialog
        show={ticketDialogShow}
        onClose={() => setTicketDialogShow(false)}
        handleContinueGameStart={handleContinueGameStart}
      />
      <LocalizedLink href={`/game`} prefetch={true} ref={linkRef} />
    </div>
  );

  return {
    PlayGameButton,
    handleGameStart,
    handleGameNextlevel,
    canEnterNextLevel,
  };
};

const ContinueDialog = ({
  isOpen,
  onClose,
  onContinue,
  journey,
  userGame,
  userInfo,
  isPending,
  tickets,
  t,
  hasSufficientBalance,
}: {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  journey: UserGameStatusDTO['journey'];
  userGame: any;
  userInfo: any;
  isPending: boolean;
  tickets: string;
  t: any;
  hasSufficientBalance: boolean;
}) => {
  const InfoItem = ({ label, value, icon }) => (
    <div className="text-nowrap">
      <span className="text-right text-[#BBB59D] inline-block">{label}:</span>
      <span className="space-x-1 ml-1">
        {label === 'Balance' ? (
          <span className="inline-block ">{formatLargeNumber(value)}</span>
        ) : (
          <span className="inline-block ">{value}</span>
        )}
        {icon && (
          <Image
            className="inline-block align-middle"
            width={20}
            height={20}
            src={icon}
            alt="icon"
          />
        )}
      </span>
    </div>
  );

  const InfoSection = ({
    items,
    showTips = false,
  }: {
    items: Array<any>;
    showTips: boolean;
  }) => {
    const left = items.slice(0, items.length / 2);
    const right = items.slice(items.length / 2, items.length);
    const levelText =
      journey.currentLevel < 6 ? (
        <p className={'text-sm font-bold'}>
          Level <span className={'text-[#FAD54A]'}>{journey.currentLevel}</span>{' '}
          now, <span className={'text-[#7FC272]'}>beat it to win Pina!</span>{' '}
        </p>
      ) : (
        <p className={'text-sm font-bold whitespace-nowrap'}>
          The last level, beat it to win{' '}
          <span className={'text-[#FF9900]'}>Rare Pina!</span>
        </p>
      );
    return (
      <div className={'bg-[#25241C] rounded-xl py-3 px-3 text-[14px]'}>
        <div className="text-center font-bold mb-2 font-comicbd text-[16px]">
          {levelText}
        </div>
        <div
          className={
            'w-full font-comicbd font-bold flex flex-row justify-between py-2 '
          }
        >
          {left.map((item, index) => (
            <InfoItem key={index} {...item} />
          ))}
        </div>
        <div
          className={
            'w-full font-comicbd font-bold flex flex-row justify-between'
          }
        >
          {right.map((item, index) => (
            <InfoItem key={index} {...item} />
          ))}
        </div>
      </div>
    );
  };
  const coinIcon = getPublicAsset('/images/lobby/coins.png');

  const gameInfo = [
    {
      label: t('message.difficulty'),
      value: (
        <DifficultyDisplay difficultyLevel={String(journey.currentLevel)} />
      ),
    },
    { label: t('message.balance'), value: userInfo.gold, icon: coinIcon },
    {
      label: t('message.cost'),
      value: formatMoney(journey?.nextPrice),
      icon: coinIcon,
    },
    {
      label: t('message.reward'),
      value: formatMoney(journey.nextRewards),
      icon: coinIcon,
    },
  ];
  const router = useRouter();
  const locale = useLocale();

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent
        enable3DBorder
        shadowColor="#3C382F"
        className={
          'bg-[#3B392D] text-white border-none outline-none shadow-none'
        }
      >
        <Image
          className="absolute z-10 top-0 -translate-y-[76.5%] w-full"
          src={getPublicAsset('/images/lobby/top.png')}
          width={555}
          height={324}
          alt="tips"
          priority
        />
        <AlertDialogTitle className="font-comicbd mt-[8%] text-sm text-center">
          <p className={'text-[1.75rem] mb-3'}>{t('start-game')}</p>
          <span className={'text-[1rem] leading-6'}>
            {t('message.trip', {
              index: journey?.index,
              tickets: Number(tickets),
            })}
          </span>
        </AlertDialogTitle>
        <InfoSection items={gameInfo} showTips={true} />
        <AlertDialogFooter className="!flex">
          {hasSufficientBalance ? (
            <>
              <AlertDialogCancel className="" onClick={onClose}>
                {t('cancel')}
              </AlertDialogCancel>
              <AlertDialogAction onClick={onContinue}>
                {isPending ? 'Launching...' : 'Continue'}
              </AlertDialogAction>
            </>
          ) : (
            <AlertDialogAction
              className=""
              onClick={() => router.push(`${locale}/earn`)}
            >
              {t('need-more-pine-coin')}
            </AlertDialogAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default usePlayGame;
